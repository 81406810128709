import _ from 'lodash'
import { Experiments } from '@wix/thunderbolt-symbols/dist'

export function addScriptGroupsBiFields(experiments: Experiments) {
	try {
		const groupsScriptResources = performance
			.getEntries()
			.filter(
				(entry) => entry.entryType === 'resource' && entry.name.includes('.js') && entry.name.includes('group_')
			)

		const countScripts = groupsScriptResources.length.toString()
		const verifyTransferSize = () =>
			experiments['specs.thunderbolt.add_transfer_size_tb_load'] &&
			groupsScriptResources.every((resource: any) => _.isNumber(resource.transferSize))

		const totalScriptsSize = verifyTransferSize()
			? groupsScriptResources
					.map((resource: any) => resource.transferSize)
					.reduce((acc, current) => acc + current, 0)
					.toString()
			: null

		return {
			countScripts,
			...(totalScriptsSize ? { totalScriptsSize } : {}),
		}
	} catch (e) {
		console.error(e)
		return {
			countScripts: '',
			totalScriptsSize: '',
		}
	}
}
